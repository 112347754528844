import { Aurelia, inject }   from 'aurelia-framework';
import { AuthService }       from 'aurelia-auth';
import { PLATFORM }          from 'aurelia-pal';
import { CustomHttpClient }  from 'resources/services/custom-http-client';
import { AureliaAuthConfig } from 'resources/configs/aurelia-auth-config';

@inject(Aurelia, AuthService, CustomHttpClient)
export class PageNavbarRight {

    /**
     * Constructor
     *
     * @param aurelia
     * @param authService
     * @param httpClient
     */
    constructor(aurelia, authService, httpClient) {
        this.aurelia     = aurelia;
        this.authService = authService;
        this.httpClient  = httpClient;
    }

    /**
     * Logs out the logged user
     */
    logout() {
        this.httpClient.get('auth/logout')
            .then(() => this.authService.logout(AureliaAuthConfig.logoutRedirect)
                .then(() => this.aurelia.setRoot(PLATFORM.moduleName('app-offline')))
                .catch(() => console.log('Error logging out')),
            );
    }

}
