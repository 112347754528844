import { inject }       from 'aurelia-framework';
import { LocalStorage } from 'resources/services/local-storage';

@inject(LocalStorage)
export class PageNavbarLeft {

    /**
     * Constructor
     *
     * @param localStorage
     */
    constructor(localStorage) {
        this.localStorage = localStorage;
    }

    /**
     * Toggles sidebar
     */
    toggleSidebar() {
        let wasCollapsed = isTrue(this.localStorage.get('sidebar-collapsed'));

        this.localStorage.set('sidebar-collapsed', !wasCollapsed);
    }

}
