import { inject }             from 'aurelia-framework';
import { UsersRepository }    from 'modules/administration/users/services/repository';
import { MessagesRepository } from 'modules/personal-area/messages/services/repository';
import { AppContainer }       from 'resources/services/app-container';
import { LaravelEchoService } from 'resources/services/laravel-echo-service';

@inject(AppContainer, LaravelEchoService, UsersRepository, MessagesRepository)
export class MessagesMediaList {

    dropdownMenu;
    createMessageAnchor;
    allMessagesAnchor;

    eventListeners = [];
    messages       = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param laravelEchoService
     * @param usersRepository
     * @param messagesRepository
     */
    constructor(appContainer, laravelEchoService, usersRepository, messagesRepository) {
        this.appContainer       = appContainer;
        this.laravelEchoService = laravelEchoService;
        this.usersRepository    = usersRepository;
        this.messagesRepository = messagesRepository;
    }

    /**
     * Handles bind event
     *
     * @returns {Promise<any[]>}
     */
    bind() {
        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeMessagesChannel();
        this.subscribeEventListeners();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        return this.usersRepository.unreadMessages().then((messages) => this.messages.splice(0, this.messages.length, ...messages));
    }

    /**
     * Subscribes messages channel
     */
    subscribeMessagesChannel() {
        if (this.laravelEchoService.instantiated) {
            window.Echo.private('App.User.' + this.appContainer.authenticatedUser.user.id)
                .listen('UserMessagesUpdated', (event) => {
                    this.appContainer.eventAggregator.publish('update-message-details');

                    let previousCount = this.messages.length;

                    this.messages.splice(0, this.messages.length, ...event.messages);

                    // TODO - THIS IS HERE ONLY FOR TESTING
                    if (this.messages.length > previousCount) {
                        let audio = new Audio('/assets/audio/notification.mp3');

                        return audio.play();
                    }
                });
        }
    }

    /**
     * Navigates to a given message
     *
     * @param message
     */
    navigateToMessage(message) {
        // marks the message has read
        this.messagesRepository.read(message.id)
            .then(() => {
                let route = this.appContainer.router.generate('personal-area.messages.details', { id: message.id });

                this.appContainer.router.navigate(route);

                // closes messages dropdown
                $(this.dropdownMenu).removeClass('show');
            });
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `create-message-anchor` & `all-messages-anchor` click event in order to close messages dropdown
        $(this.createMessageAnchor).add(this.allMessagesAnchor).on('click', () => $(this.dropdownMenu).removeClass('show'));
    }

    /**
     * Disposes event listeners
     */
    disposeEventListeners() {
        while (this.eventListeners.length) {
            this.eventListeners.pop().dispose();
        }
    }

}
