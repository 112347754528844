import { Aurelia, inject }   from 'aurelia-framework';
import { AuthService }       from 'aurelia-auth';
import { PLATFORM }          from 'aurelia-pal';
import { AuthenticatedUser } from 'resources/services/authenticated-user';
import { CustomHttpClient }  from 'resources/services/custom-http-client';
import { AureliaAuthConfig } from 'resources/configs/aurelia-auth-config';

@inject(Aurelia, CustomHttpClient, AuthService, AuthenticatedUser)
export class UserDropdown {

    /**
     * Constructor
     *
     * @param aurelia
     * @param httpClient
     * @param authService
     * @param authenticatedUser
     */
    constructor(aurelia, httpClient, authService, authenticatedUser) {
        this.aurelia           = aurelia;
        this.httpClient        = httpClient;
        this.authService       = authService;
        this.authenticatedUser = authenticatedUser;
    };

    /**
     * Logs out the logged user
     */
    logout() {
        this.httpClient.get('auth/logout')
            .then(() => this.authService.logout(AureliaAuthConfig.logoutRedirect)
                .then(() => this.aurelia.setRoot(PLATFORM.moduleName('app-offline')))
                .catch(() => console.log('Error logging out')),
            );
    }

}
