import { inject }          from 'aurelia-framework';
import { AppContainer }    from 'resources/services/app-container';
import { LiftsRepository } from 'modules/lifts/lifts/services/repository';

@inject(AppContainer, LiftsRepository)
export class LiftsQuickSearch {

    liftCode    = '';
    liftAddress = '';

    /**
     * Constructor
     *
     * @param appContainer
     * @param liftsRepository
     */
    constructor(appContainer, liftsRepository) {
        this.appContainer    = appContainer;
        this.liftsRepository = liftsRepository;
    }

    /**
     * Performs a search by code
     */
    searchByCode() {
        this.liftCode = this.liftCode.trim();

        if (this.liftCode.trim().length) {
            this.liftsRepository.findByCode(this.liftCode).then((response) => {
                if (response.status && response.lift) {
                    this.liftCode = null;

                    return this.appContainer.router.navigateToRoute('lifts.lifts.view', {id: response.lift.id});
                }

                this.appContainer.notifier.dangerNotice(response.message);
            });
        }
    }

    /**
     * Performs a search by address
     */
    searchByAddress() {
        this.liftAddress = this.liftAddress.trim();

        if (this.liftAddress.trim().length) {
            let search = this.liftAddress;

            this.liftAddress = null;

            return this.appContainer.router.navigateToRoute('lifts.search', { address: encodeURIComponent(search) });
        }
    }

}
